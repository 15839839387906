$black: rgba(0, 0, 0, 0.87);
$white: #fafafa;

body {
  overflow: hidden;
}

.site {
  &::before,
  &::after {
    content: '';
    display: block;
    width: 2000vh;
    height: 2000vh;
    position: absolute;
    top: 75vh;
    right: -900vh;
    background-image: url(waves.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: -1;
  }

  &::before {
    animation: spin 1100s linear infinite;
    background-size: 99.7%;
  }

  &::after {
    animation: spin 700s linear infinite;
  }

  &__wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
  }

  ::selection {
    background: $black;
    color: $white;
  }
}

.site-content {
  // flex-grow: 1;
  color: #444;
}

.blinkingCursor {
  position: absolute;

  &::after {
    content: '\00a0';
    animation: blink 1s step-end infinite;
    background-color: currentColor;
    display: inline-block;
  }
}

@keyframes spin {
  100% { transform: rotate(360deg); }
}

@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: currentColor;
  }
}